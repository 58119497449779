import { onMounted, ref } from "vue";

export function useStylesLoaded(
  names: string[],
  artificialResolveDelay: number,
  retryInterval: number,
  maxAttempts: number = 40
) {
  const stylesLoaded = ref(false);
  const attempts = ref(0);

  function checkAllStylesLoaded() {
    return new Promise((resolve, reject) => {
      const checkStyles = () => {
        attempts.value++;
        if (attempts.value > maxAttempts) {
          reject(new Error("Max retry attempts reached, styles not loaded"));
          return;
        }

        try {
          const allLoaded = names.every((name) => {
            const styleSheet = [...document.styleSheets].find(
              (sheet) => sheet.href && sheet.href.includes(name)
            );
            return styleSheet && styleSheet.cssRules;
          });

          if (allLoaded) {
            setTimeout(() => {
              resolve(true);
            }, artificialResolveDelay);
          } else {
            setTimeout(checkStyles, retryInterval);
          }
        } catch (e) {
          reject(new Error("Error checking styles"));
        }
      };

      checkStyles();
    });
  }

  onMounted(async () => {
    try {
      const loaded = await checkAllStylesLoaded();
      if (loaded) {
        stylesLoaded.value = true;
      }
    } catch (error) {
      console.error("Failed to load styles: ", error);
    }
  });

  return { stylesLoaded };
}
