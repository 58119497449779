<script setup lang="ts"></script>
<template>
  <div class="flex-container">
    <div class="flex-content">
      <div class="loader-container">
        <Loader />
      </div>
    </div>
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.flex-content {
  flex: 1;
  position: relative;
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
</style>
